import React from 'react';
import { string, bool } from 'prop-types';
import { Amplitude } from '@amplitude/react-amplitude';
import Paper from '@material-ui/core/Paper';

import edpLogoSmall from '../../edp-small.png';
import { generateReportUrl } from '../../util/reports';
import { examDiffProUrl } from '../../util/common';

const OutputFrame = ({ reportId, durationKey, isReportReady }) => (
  <Amplitude>
    {({ logEvent }) => (
      <>
        <Paper
          onClick={() => {
            logEvent('CLICKED_RESULTS_GENERATED_BY_EXAMDIFF_PRO');
            window.open(examDiffProUrl('diffnow-generated-by'), '_blank');
          }}
          style={{
            display: reportId && isReportReady ? 'flex' : 'none',
            marginTop: 20,
            backgroundColor: '#1976d2',
            fontFamily: '"Open Sans", Roboto, sans-serif',
            color: 'white',
            padding: 10,
            fontSize: 14,
            cursor: 'pointer'
          }}
        >
          <img src={edpLogoSmall} alt="ExamDiff Pro" style={{ marginRight: 10 }} />
          <span
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Comparison results generated by ExamDiff Pro, a powerful all-in-one file and folder
            comparison Windows desktop application. Click this banner to learn more!
          </span>
        </Paper>
        <Paper
          style={{
            padding: 12,
            display: reportId && isReportReady ? 'block' : 'none'
          }}
        >
          {isReportReady && (
            <iframe
              id="diff-report-frame"
              title="DiffNow Output Frame"
              style={{
                width: '100%',
                height: 600,
                border: 'none'
              }}
              src={reportId && generateReportUrl(reportId, durationKey)}
            />
          )}
        </Paper>
      </>
    )}
  </Amplitude>
);

OutputFrame.propTypes = {
  reportId: string,
  durationKey: string,
  isReportReady: bool
};

export default OutputFrame;
