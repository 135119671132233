import zIndex from '@material-ui/core/styles/zIndex';
import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  zIndex: Object.assign({}, zIndex, {
    appBar: 2000
  }),
  typography: {
    fontFamily: '"Open Sans", Roboto, sans-serif'
  },
  palette: {
    primary: {
      light: '#63a4ff',
      main: '#1976d2',
      dark: '#004ba0',
      contrastText: '#ffffff',
      optionsText: '#000000'
    },
    secondary: {
      light: '#ffff8b',
      main: '#ffee58',
      dark: '#c9bc1f',
      contrastText: '#000000'
    },
    background: {
      default: '#eeeeee'
    },
    clipBackground: {},
    tab: {
      selected: '#448AFF',
      unselected: '#1976d2'
    },
    topButton: {
      backgroundColor: '#1E88E5',
      color: '#ffffff'
    },
    buttonBorder: '1px solid #CCCCCC',
    status: {
      error: {
        color: '#ffffff',
        backgroundColor: '#E57373'
      },
      success: {
        color: '#ffffff',
        backgroundColor: '#81C784'
      },
      tip: {
        color: '#000000',
        backgroundColor: '#FFF176'
      },
      info: {
        color: '#000000',
        backgroundColor: '#C5E1A5'
      }
    },
    optionsDialog: {
      titleBarColor: '#DDDDDD'
    },
    selectedProfileColor: '#eeeeee',
    unselectedProfileColor: '#ffffff',
    iconColor: '#222222',
    disclaimerTextColor: '#444444'
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          paddingRight: 0
        }
      },
      head: {
        '&:not(:first-child)': {
          width: '19%'
        }
      }
    }
  }
});

export const darkTheme = createMuiTheme({
  zIndex: Object.assign({}, zIndex, {
    appBar: 2000
  }),
  typography: {
    fontFamily: '"Open Sans", Roboto, sans-serif'
  },
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff',
      optionsText: '#cccccc'
    },
    secondary: {
      light: '#a4a4a4',
      main: '#757575',
      dark: '#494949',
      contrastText: '#000000'
    },
    background: {
      default: '#2b2b2b'
    },
    clipBackground: {
      backgroundColor: '#585858',
      color: '#eeeeee'
    },
    tab: {
      selected: '#484848',
      unselected: '#212121'
    },
    topButton: {
      backgroundColor: '#757575',
      color: '#ffffff'
    },
    buttonBorder: '1px solid #444444',
    status: {
      error: {
        color: '#ffffff',
        backgroundColor: '#757575'
      },
      success: {
        color: '#ffffff',
        backgroundColor: '#616161'
      },
      tip: {
        color: '#000000',
        backgroundColor: '#9E9E9E'
      },
      info: {
        color: '#000000',
        backgroundColor: '#BDBDBD'
      }
    },
    optionsDialog: {
      titleBarColor: '#222222'
    },
    selectedProfileColor: '#777777',
    unselectedProfileColor: '#383838',
    iconColor: '#dddddd',
    disclaimerTextColor: '#999999'
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#383838',
        color: '#cccccc'
      }
    },
    MuiTypography: {
      body1: {
        color: '#cccccc'
      },
      title: {
        color: '#cccccc'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#cccccc'
      }
    },
    MuiInput: {
      root: {
        color: '#cccccc'
      },
      underline: {
        '&:before': {
          borderBottomColor: '#cccccc'
        }
      }
    },
    MuiMenuItem: {
      root: {
        color: '#cccccc'
      }
    },
    MuiButton: {
      root: {
        color: '#ffffff',
        '&$disabled': {
          color: '#777777'
        }
      },
      textPrimary: {
        color: '#ffffff'
      },
      contained: {
        '&$disabled': {
          color: '#777777'
        },
        boxShadow: 'none'
      },
      containedPrimary: {
        backgroundColor: '#cccccc',
        color: '#000000',
        '&:hover': {
          backgroundColor: '#888888'
        }
      },
      containedSecondary: {
        backgroundColor: '#757575',
        color: '#ffffff'
      },
      outlined: {
        color: '#dddddd'
      },
      outlinedPrimary: {
        borderColor: '#eeeeee',
        color: '#ffffff',
        '&:hover': {
          borderColor: '#ffffff'
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: '#eeeeee'
      },
      colorPrimary: {
        '&$checked': {
          color: '#cccccc'
        }
      }
    },
    MuiRadio: {
      root: {
        color: '#eeeeee'
      },
      colorPrimary: {
        '&$checked': {
          color: '#cccccc'
        }
      }
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          paddingRight: 0
        }
      },
      body: {
        color: '#ffffff'
      },
      head: {
        color: '#dddddd',
        '&:not(:first-child)': {
          width: '19%'
        }
      }
    }
  }
});
