import React, { Component } from 'react';
import Media from 'react-media';
import { func, object, array } from 'prop-types';
import { NavLink } from 'react-router-dom';
import find from 'lodash/find';
import noop from 'lodash/noop';
import { withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import ReactTooltip from 'react-tooltip';
/* eslint-disable import/no-unassigned-import */
import 'whatwg-fetch';
/* eslint-enable import/no-unassigned-import */

import { logout } from '../util/user';
import { setCurrentProfile } from '../util/options';
import { isPremium } from '../util/common';
import UserMenuButton from '../components/users/UserMenuButton';
import diffnowLogo from '../diffnow.png';

class TitleBar extends Component {
  static propTypes = {
    user: object,
    optionsProfiles: array,
    onUserLogout: func,
    onProfileSelect: func,
    toggleNightMode: func,
    openLoginDialog: func,
    openSavedReportsDialog: func,
    openWhyPremiumDialog: func,
    openChangePasswordDialog: func,
    openManagePremiumSubscriptionDialog: func,
    theme: object
  };

  state = {
    userOpen: false,
    userAnchorEl: null,
    profilesOpen: false,
    profilesAnchorEl: null
  };

  openProfilesMenu = event => {
    event.preventDefault();

    this.setState({
      profilesOpen: true,
      profilesAnchorEl: event.currentTarget
    });
  };

  closeProfilesMenu = () => {
    this.setState({ profilesOpen: false });
  };

  openUserMenu = event => {
    event.preventDefault();

    this.setState({
      userOpen: true,
      userAnchorEl: event.currentTarget
    });
  };

  closeUserMenu = () => {
    this.setState({ userOpen: false });
  };

  handleClickChangePassword = () => {
    this.props.openChangePasswordDialog();
    this.closeUserMenu();
  };

  handleClickManagePremiumSubscription = () => {
    this.props.openManagePremiumSubscriptionDialog();
    this.closeUserMenu();
  };

  handleToggleNightMode = () => {
    this.props.toggleNightMode();
    this.closeUserMenu();
  };

  handleClickLogout = () => {
    logout(this.props.user.sessionToken, this.props.onUserLogout);
    this.closeUserMenu();
  };

  selectProfile = profile => {
    setCurrentProfile(this.props.user.sessionToken, profile, this.props.onProfileSelect);
    this.closeProfilesMenu();
  };

  redirectToClassicDiffNow = () => {
    window.location.href = 'https://www.diffnow.com/v1';
  };

  get styles() {
    return {
      outer: {
        height: 64
      },
      logo: {
        marginTop: 10,
        height: '70%'
      },
      buttonList: {
        display: 'flex'
      },
      buttonContainer: {
        marginLeft: 10
      },
      getPremiumButton: {
        marginLeft: 20,
        display: 'flex',
        alignItems: 'center'
      },
      buttonText: {
        color: 'white'
      }
    };
  }

  optionsProfiles(matches) {
    const { optionsProfiles, user, theme } = this.props;

    const currentProfile = find(optionsProfiles, ['current_profile', 1]);
    const selectedProfile = currentProfile ? currentProfile.options_name : 'Default Profile';
    const profilesList = optionsProfiles.map((profile, index) => (
      <MenuItem
        key={index}
        style={{
          backgroundColor:
            selectedProfile === profile.options_name
              ? theme.palette.selectedProfileColor
              : theme.palette.unselectedProfileColor
        }}
        onClick={() => this.selectProfile(profile.options_name)}
      >
        {profile.options_name}
      </MenuItem>
    ));

    return (
      <div style={this.styles.buttonContainer}>
        {!isPremium(user) && <ReactTooltip id="options-profiles-tooltip" />}
        <div
          data-tip="Upgrade to DiffNow Premium to use options profiles"
          data-for="options-profiles-tooltip"
          data-place="bottom"
        >
          <UserMenuButton
            aria-owns={this.state.profilesAnchorEl ? 'profiles-menu' : null}
            aria-haspopup="true"
            disabled={!isPremium(user)}
            label={matches.large && selectedProfile}
            action={isPremium(user) ? this.openProfilesMenu : noop}
            icon="settings"
          />
        </div>
        <Menu
          id="profiles-menu"
          open={this.state.profilesOpen}
          anchorEl={this.state.profilesAnchorEl}
          onClose={this.closeProfilesMenu}
          TransitionComponent={Fade}
        >
          {profilesList}
        </Menu>
      </div>
    );
  }

  userMenu = matches => {
    if (this.props.user) {
      return (
        <div style={this.styles.buttonList}>
          {this.optionsProfiles(matches)}
          <div style={this.styles.buttonContainer}>
            <UserMenuButton
              label={matches.large && 'Saved Reports'}
              action={this.props.openSavedReportsDialog}
              icon="save"
            />
          </div>
          <div style={this.styles.buttonContainer}>
            <UserMenuButton
              aria-owns={this.state.userAnchorEl ? 'user-menu' : null}
              aria-haspopup="true"
              label={matches.large && this.props.user.email}
              labelStyle={{ textTransform: 'none' }}
              action={this.openUserMenu}
              icon="account_circle"
            />
            <Menu
              id="user-menu"
              open={this.state.userOpen}
              anchorEl={this.state.userAnchorEl}
              onClose={this.closeUserMenu}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={this.handleClickChangePassword}>
                <Icon style={{ marginRight: 20 }}>vpn_key</Icon>
                Change Password
              </MenuItem>
              {this.props.user.isPremium && (
                <MenuItem onClick={this.handleClickManagePremiumSubscription}>
                  <Icon style={{ marginRight: 20 }}>stars</Icon>
                  Manage Premium Subscription
                </MenuItem>
              )}
              <MenuItem onClick={this.handleToggleNightMode}>
                <Icon style={{ marginRight: 20 }}>invert_colors</Icon>
                Toggle Night Mode
              </MenuItem>
              <MenuItem onClick={this.handleClickLogout}>
                <Icon style={{ marginRight: 20 }}>exit_to_app</Icon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      );
    } else {
      return (
        <Button variant="flat" onClick={this.props.openLoginDialog} style={{ color: 'white' }}>
          {!matches.small && 'Login'}
          <Icon style={{ marginLeft: !matches.small ? 10 : 0 }}>person</Icon>
        </Button>
      );
    }
  };

  title(matches) {
    return (
      <div
        style={{
          display: 'flex'
        }}
      >
        <NavLink
          to="/compare-clips"
          style={{
            height: 64
          }}
        >
          <img src={diffnowLogo} style={this.styles.logo} alt="DiffNow" />
        </NavLink>
        {!isPremium(this.props.user) ? (
          <div style={this.styles.getPremiumButton}>
            <UserMenuButton
              label={!matches.small && 'Get Premium'}
              action={() => this.props.openWhyPremiumDialog('TITLE_BAR')}
              icon="star"
              backgroundColor="#FFEE58"
              hoverColor="#FFF176"
              textColor="#444444"
            />
            <Button
              variant="flat"
              onClick={() => this.props.openWhyPremiumDialog('TITLE_BAR_WHY')}
              style={{ color: 'white', marginLeft: 10 }}
            >
              Why?
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <Media
        queries={{
          small: '(max-width: 599px)',
          medium: '(min-width: 600px) and (max-width: 874px)',
          large: '(min-width: 875px)'
        }}
      >
        {matches => (
          <div style={this.styles.outer}>
            <AppBar position="static" color="primary">
              <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.title(matches)}
                {this.userMenu(matches)}
              </Toolbar>
            </AppBar>
          </div>
        )}
      </Media>
    );
  }
}

export default withTheme()(TitleBar);
