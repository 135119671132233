import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, number, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import { COMPARE_PANE_TRANSITION_MS } from '../util/constants';
import * as actions from '../actions/global';

function mapStateToProps(state) {
  return {
    collapsedTabIndex: state.global.collapsedTabIndex
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSetCollapsedTabIndex(newCollapsedTabIndex) {
      dispatch(actions.setCollapsedTabIndex(newCollapsedTabIndex));
    }
  };
}

class Samples extends Component {
  static propTypes = {
    onSetCollapsedTabIndex: func,
    collapsedTabIndex: number,
    theme: object
  };

  constructor(props) {
    super(props);

    props.onSetCollapsedTabIndex(null);

    this.state = {
      open: false
    };
  }

  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    open: props.collapsedTabIndex === null
  });

  componentDidMount() {
    this.props.onSetCollapsedTabIndex(null);
  }

  renderSampleButton(label, url1, url2) {
    const DEMO_URL = 'https://www.diffnow.com/demo/';

    return (
      <Button
        style={{ marginRight: 10, marginBottom: 10, ...this.props.theme.palette.topButton }}
        component={Link}
        to={`/compare-urls?url1=${DEMO_URL}${url1}&url2=${DEMO_URL}${url2}&demo=1`}
      >
        {label}
      </Button>
    );
  }

  get styles() {
    return {
      outer: {
        padding: 20
      },
      contents: {
        display: 'flex',
        flexDirection: 'column'
      },
      buttonList: {
        display: 'flex',
        flexWrap: 'wrap'
      },
      heading: {
        fontSize: 24,
        marginBottom: 10,
        color: '#666'
      },
      divider: {
        marginBottom: 10
      }
    };
  }

  render() {
    return (
      <div>
        <Paper style={this.state.open ? this.styles.outer : {}}>
          <Collapse
            in={this.state.open}
            timeout={COMPARE_PANE_TRANSITION_MS}
            style={this.state.open ? {} : { visibility: 'hidden' }}
          >
            <div style={this.styles.contents}>
              <div style={this.styles.buttonList}>
                {this.renderSampleButton('Plain text', '1.txt', '2.txt')}
                {this.renderSampleButton('Word documents', '1.doc', '2.doc')}
                {this.renderSampleButton('PDF documents', '1.pdf', '2.pdf')}
                {this.renderSampleButton('HTML', '1.html', '2.html')}
                {this.renderSampleButton('JavaScript', 'lightbox1.js', 'lightbox2.js')}
                {this.renderSampleButton('Binary files', '1.bin', '2.bin')}
                {this.renderSampleButton('ZIP archives', '1.zip', '2.zip')}
                {this.renderSampleButton('Unicode', 'unicode1.txt', 'unicode2.txt')}
              </div>
              <Divider style={this.styles.divider} />
              <Typography component={'span'}>
                <div style={this.styles.heading}>What else can I do with DiffNow?</div>
                <div>DiffNow also lets you compare:</div>
                <ul>
                  <li>Microsoft Office documents (doc, docx, xls, xlsx, ppt, pptx)</li>
                  <li>Source code in C, C++, C#, Java, Perl, PHP, and other languages</li>
                  <li>RAR, 7-zip and other archive formats</li>
                </ul>
              </Typography>
            </div>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

export default withTheme()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Samples)
);
